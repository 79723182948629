import React, { useState } from 'react';
import './Admin.css';
import WorkoutView from './components/WorkoutView';

const Admin = () => {
  const [activeView, setActiveView] = useState('workouts');

  const renderView = () => {
    switch (activeView) {
      case 'workouts':
        return <WorkoutView />;
      // Additional views can be added here
      default:
        return <WorkoutView />;
    }
  };

  return (
    <div className="admin-container">
      <h1>Admin Dashboard</h1>
      
      <div className="admin-nav">
        <button 
          className={`admin-nav-button ${activeView === 'workouts' ? 'active' : ''}`}
          onClick={() => setActiveView('workouts')}
        >
          Workout View
        </button>
        {/* Additional buttons can be added here */}
      </div>

      <div className="admin-content">
        {renderView()}
      </div>
    </div>
  );
};

export default Admin;
