import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const WorkoutView = () => {
  const [workouts, setWorkouts] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [editingWorkout, setEditingWorkout] = useState(null);
  const [workoutNotes, setWorkoutNotes] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    fetchWorkouts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const fetchWorkouts = async () => {
    try {
      setLoading(true);
      
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      // First get the workouts
      const { data: workoutsData, error: workoutsError } = await supabase
        .from('workouts')
        .select(`
          *,
          clients (
            first_name,
            last_name
          ),
          workout_exercises (
            sets,
            reps,
            time,
            weight,
            exercises (
              name,
              muscle_group
            )
          )
        `)
        .gte('workout_date', startDate.toISOString())
        .lt('workout_date', endDate.toISOString())
        .order('workout_date', { ascending: true });

      if (workoutsError) throw workoutsError;

      if (workoutsData) {
        // Get unique trainer IDs
        const trainerIds = [...new Set(workoutsData.map(w => w.trainer_id))];
        
        // Get trainer display names from profiles table
        const { data: profilesData } = await supabase
          .from('profiles')
          .select('id, display_name')
          .in('id', trainerIds);

        // Create a map of trainer IDs to display names
        const trainerNames = profilesData.reduce((acc, profile) => {
          acc[profile.id] = profile.display_name;
          return acc;
        }, {});

        // Group workouts by trainer and include their display name
        const workoutsByTrainer = workoutsData.reduce((acc, workout) => {
          const trainerId = workout.trainer_id;
          if (!acc[trainerId]) {
            acc[trainerId] = {
              displayName: trainerNames[trainerId] || 'Unknown',
              workouts: []
            };
          }
          acc[trainerId].workouts.push(workout);
          return acc;
        }, {});

        setWorkouts(workoutsByTrainer);
      } else {
        setWorkouts({});
      }

    } catch (error) {
      console.error('Error:', error);
      setWorkouts({});
    } finally {
      setLoading(false);
    }
  };

  const handleToggleCompletion = async (workout) => {
    try {
      setUpdateLoading(true);
      const newStatus = !workout.completed;
      
      const { error } = await supabase
        .from('workouts')
        .update({ completed: newStatus })
        .eq('id', workout.id);
      
      if (error) throw error;
      
      // Update local state
      const updatedWorkouts = { ...workouts };
      const trainerWorkouts = updatedWorkouts[workout.trainer_id];
      
      if (trainerWorkouts) {
        const workoutIndex = trainerWorkouts.workouts.findIndex(w => w.id === workout.id);
        if (workoutIndex !== -1) {
          trainerWorkouts.workouts[workoutIndex].completed = newStatus;
          setWorkouts(updatedWorkouts);
        }
      }
    } catch (error) {
      console.error('Error toggling completion status:', error);
      alert('Failed to update workout status');
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleDeleteWorkout = async (workout) => {
    if (!window.confirm('Are you sure you want to delete this workout?')) {
      return;
    }
    
    try {
      setUpdateLoading(true);
      
      // First delete the workout exercises
      const { error: exercisesError } = await supabase
        .from('workout_exercises')
        .delete()
        .eq('workout_id', workout.id);
      
      if (exercisesError) throw exercisesError;
      
      // Then delete the workout
      const { error: workoutError } = await supabase
        .from('workouts')
        .delete()
        .eq('id', workout.id);
      
      if (workoutError) throw workoutError;
      
      // Update local state
      const updatedWorkouts = { ...workouts };
      const trainerWorkouts = updatedWorkouts[workout.trainer_id];
      
      if (trainerWorkouts) {
        trainerWorkouts.workouts = trainerWorkouts.workouts.filter(w => w.id !== workout.id);
        
        // If this was the last workout for this trainer, remove the trainer section
        if (trainerWorkouts.workouts.length === 0) {
          delete updatedWorkouts[workout.trainer_id];
        }
        
        setWorkouts(updatedWorkouts);
      }
    } catch (error) {
      console.error('Error deleting workout:', error);
      alert('Failed to delete workout');
    } finally {
      setUpdateLoading(false);
    }
  };

  const openEditModal = (workout) => {
    setEditingWorkout(workout);
    setWorkoutNotes(workout.notes || '');
    setIsEditing(true);
  };

  const closeEditModal = () => {
    setEditingWorkout(null);
    setWorkoutNotes('');
    setIsEditing(false);
  };

  const handleUpdateWorkout = async () => {
    if (!editingWorkout) return;
    
    try {
      setUpdateLoading(true);
      
      const { error } = await supabase
        .from('workouts')
        .update({ notes: workoutNotes })
        .eq('id', editingWorkout.id);
      
      if (error) throw error;
      
      // Update local state
      const updatedWorkouts = { ...workouts };
      const trainerWorkouts = updatedWorkouts[editingWorkout.trainer_id];
      
      if (trainerWorkouts) {
        const workoutIndex = trainerWorkouts.workouts.findIndex(w => w.id === editingWorkout.id);
        if (workoutIndex !== -1) {
          trainerWorkouts.workouts[workoutIndex].notes = workoutNotes;
          setWorkouts(updatedWorkouts);
        }
      }
      
      closeEditModal();
    } catch (error) {
      console.error('Error updating workout:', error);
      alert('Failed to update workout notes');
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <div className="workout-view">
      <div className="date-selector">
        <h2>Select Date</h2>
        <DatePicker
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          dateFormat="MMMM d, yyyy"
          className="date-picker"
        />
      </div>

      {loading ? (
        <div className="loading">Loading workouts...</div>
      ) : Object.keys(workouts).length === 0 ? (
        <div className="no-workouts">No workouts found for this date.</div>
      ) : (
        <div className="trainers-container">
          {Object.entries(workouts).map(([trainerId, trainerWorkouts]) => (
            <div key={trainerId} className="trainer-section">
              <h2>Trainer: {trainerWorkouts.displayName}</h2>
              <div className="workouts-grid">
                {trainerWorkouts.workouts.map((workout) => (
                  <div key={workout.id} className="workout-plan-card">
                    <div className="client-info">
                      <h3>
                        {workout.clients?.first_name} {workout.clients?.last_name}
                      </h3>
                      <span className={`status ${workout.completed ? 'completed' : 'pending'}`}>
                        {workout.completed ? 'Completed' : 'Pending'}
                      </span>
                    </div>

                    <div className="workout-details">
                      <div className="details-header">
                        <span className="exercise-col">Exercise</span>
                        <span className="detail-col">Sets</span>
                        <span className="detail-col">Reps</span>
                        <span className="detail-col">Weight</span>
                        <span className="detail-col">Time</span>
                      </div>

                      {workout.workout_exercises.map((exercise, index) => (
                        <div key={index} className="exercise-row">
                          <div className="exercise-col">
                            <span className="exercise-name">{exercise.exercises.name}</span>
                            <span className="muscle-group">({exercise.exercises.muscle_group})</span>
                          </div>
                          <span className="detail-col">{exercise.sets || '-'}</span>
                          <span className="detail-col">{exercise.reps || '-'}</span>
                          <span className="detail-col">{exercise.weight || '-'}</span>
                          <span className="detail-col">{exercise.time || '-'}</span>
                        </div>
                      ))}
                    </div>

                    <div className="workout-time">
                      Time: {new Date(workout.workout_date).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>

                    {workout.notes && (
                      <div className="workout-notes">
                        <h4>Notes:</h4>
                        <p>{workout.notes}</p>
                      </div>
                    )}
                    
                    <div className="workout-actions">
                      <button 
                        className="action-button toggle"
                        onClick={() => handleToggleCompletion(workout)}
                        disabled={updateLoading}
                      >
                        {workout.completed ? 'Mark Incomplete' : 'Mark Complete'}
                      </button>
                      <button 
                        className="action-button edit"
                        onClick={() => openEditModal(workout)}
                        disabled={updateLoading}
                      >
                        Edit Notes
                      </button>
                      <button 
                        className="action-button delete"
                        onClick={() => handleDeleteWorkout(workout)}
                        disabled={updateLoading}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {isEditing && editingWorkout && (
        <div className="modal-overlay">
          <div className="edit-modal">
            <h3>Edit Workout Notes</h3>
            <textarea
              value={workoutNotes}
              onChange={(e) => setWorkoutNotes(e.target.value)}
              placeholder="Enter workout notes..."
              rows={5}
              className="notes-textarea"
            />
            <div className="modal-actions">
              <button 
                className="modal-button cancel"
                onClick={closeEditModal}
                disabled={updateLoading}
              >
                Cancel
              </button>
              <button 
                className="modal-button save"
                onClick={handleUpdateWorkout}
                disabled={updateLoading}
              >
                {updateLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkoutView; 